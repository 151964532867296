@import "bootstrap-grid.min.css";
@import '@mateco/zenith-webcomponents/dist/zenith-webcomponents/styles/zenith';

.container-fluid {
  // this is because OX already handles margin
  margin-left: -15px;
  margin-right: -15px;
}

.clickable {
  cursor: pointer;
}

.text-nowrap {
  white-space: nowrap;
}

tr.clickable:hover {
  background-color: #e8f0f0;
}

.ox-form__group__with_checkbox {
  margin-bottom: 0 !important;
}

.ox-button-group-button-active {
  background-color: #f4f7f7;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.valign-top {
  vertical-align: top;
}

.vertical-align-text-top {
  vertical-align: text-top;
}

.ox-sortable-header {
  display: block;
}

.tab-navigation {
  border: 0;
  box-shadow: none;
}

.ox-popover__content {
  max-width: 100% !important;
  min-width: 200px;
}

.qplanning_instance_info_snippet {
  width: 700px;
  min-height: 500px;
}

.ox-modal__body__scrollbar {
  max-height: 80vh;
  overflow: auto;
}

.ox-modal__body__min-height {
  min-height: 80vh;
}

.ox-modal__body__small {
  max-width: 40vw;
}

.ox-layout__main__content {
  overflow: inherit; // Prevent popovers (at bottom of page) from being cut off by this container.
}

.ox-filters-wrapper {
  margin-right: 200px; // Prevent dropdown filter layout from being clipped by canvas.
}

.table-condensed-horizontally td {
  padding-left: 0;
  padding-right: 0;
}

.text-uppercase {
  text-transform: uppercase;
}

.ox-text--update {
  color: var(--ox-color-update)
}

// See https://getbootstrap.com/docs/5.0/utilities/text/#word-break
.text-break {
  word-wrap: break-word;
  word-break: break-word;
}

.w-100 {
  width: 100%;
}

.mw-200 {
  min-width: 200px;
}

.fw-bold {
  font-weight: bold;
}
